import styled from 'styled-components';

import {EditButton} from '../Article/styles';

const EditVideoButton = styled(EditButton)`
  padding:0;
  width:85px;
`;

const VideoContainer = styled.div`
  width: 100%;
  float: left;
  margin: 0 auto;
  margin-top: 20px;
  margin-top: ${({margin}) => margin ? '0px' : '20px'};
  background: white;
  
  > div {
    width: 100% !important;
  }
  .react-player {
    height: 480px !important;
    height: ${({width}) => width ? '480px !important' : '480px !important'};
    @media (max-width: 500px) {
      height: 200px !important;
    }
    @media (max-width: 1500px) {
      height: 395px !important;
    }
    >div{
      border-radius: 6px 6px 0 0;
    }
  }

  .react-player-webinar {
    height: 400px !important;
    @media (max-width: 500px) {
      height: 200px !important;
    }
  }
`;

const VideoContainerNew = styled.div`
  width: 100%;
  float: left;
  margin: 0 auto;
  margin-top: 20px;
  margin-top: ${({margin}) => margin ? '0px' : '20px'};
  background: white;
  
  > div {
    width: 100% !important;
  }
  .react-player {
    height: 460px !important;
    @media (max-width: 500px) {
      height: 200px !important;
    }

    @media (max-width: 1490px) {
      height: 451px !important;
    }
    @media (max-width: 1465px) {
      height: 440px !important;
    }
    @media (max-width: 1435px) {
      height: 430px !important;
    }
    @media (max-width: 1410px) {
      height: 420px !important;
    }
    @media (max-width: 1385px) {
      height: 410px !important;
    }
    @media (max-width: 1360px) {
      height: 400px !important;
    }
    @media (max-width: 1335px) {
      height: 390px !important;
    }
    @media (max-width: 1305px) {
      height: 380px !important;
    }
    @media (max-width: 1280px) {
      height: 370px !important;
    }
 
    >div{
      border-radius: 6px 6px 0 0;
    }
  }

  .react-player-webinar {
    height: 400px !important;
    @media (max-width: 500px) {
      height: 200px !important;
    }
  }
`;

const Layout = styled.div`
  width: 100%;
  display: block;
  .float {
    width; 100%;
    float; left;
  }
>div:first-child{
  width: 100%;
}
> div: last-child {
  width: 100%;
 
}
  @media (max-width: 500px) {
    padding: 0 10px 30px;
  }
  `;

export { VideoContainer, EditVideoButton, Layout, VideoContainerNew };