import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import { VideoContainer, Layout, VideoContainerNew } from './styles';
import { Title, AdventureTitle, Button, ButtonContainer, TitleContainer, AdminButtons } from '../Article/styles';
import { SideBarContainer, SocialFeedsContainer, NoComments } from '../SocialFeeds/styles';
import { EngagementContainer } from '../EngagementSection/styles';
import { CardContainer, Title as RecommendedTitle } from '../AdventureCategories/styles';
import { ArticleButtonsContainer, BackButton, CircleContainerNew } from '../Journeys/styles';
import AdventurePointDashBoard from '../AdventurePointDashBoard';
import Waiting from '../Waiting';
import RecommendedVideos from '../RecommendedVideos/recommendedVideo';
import { getVideoLibraryArticle, getWebinarLibraryArticle, getFitnessVideoById, getRecommendedVideos, getFavoriteVideos, getFeaturedVideos } from '../../redux/actions';
import { Circle, Tick } from '../Journeys/styles';
import { ImageContainerIcons } from '../Article/styles';
import { withTranslation } from 'react-i18next';

class VideoArticle extends Component {
  constructor() {
    super();
    this.state = {
      save: 0,
      like: 0,
      imagestatus: 0
    }
  }
  componentDidMount() {
    const { location: { pathname }, fetchFitnessVideoById } = this.props;
    const StringArray = pathname.split('/');
    fetchFitnessVideoById(StringArray[(StringArray.length - 1)]);
  }

  componentDidUpdate(prevProps) {
    const { location, fetchFitnessVideoById } = this.props;
    const StringArray = location.pathname.split('/');
    if (location.pathname !== prevProps.location.pathname) {
      if (StringArray[(StringArray.length - 1)] != '') {
        fetchFitnessVideoById(StringArray[(StringArray.length - 1)]);
      }
    }
  }

  getNextArticle = () => {
    const { history, videoIds, link, webinarVideoIds } = this.props;
    const stringArray = history.location.pathname.split('/');
    const videoId = stringArray[stringArray.length - 1];
    if (stringArray[2] === 'videos-library') {
      const index = videoIds && videoIds.findIndex((id) => videoId == id);
      history.push(`/education/${link}/${stringArray[stringArray.length - 3]}/fitness/${videoIds[index + 1]}`);
    } else {
      const index = webinarVideoIds && webinarVideoIds.findIndex((id) => videoId == id);
      history.push(`/education/${link}/${stringArray[stringArray.length - 3]}/article/${webinarVideoIds[index + 1]}`);
    }
  };

  callBack = () => {
    const { fetchVideoArticle, fetchRecommendedVideos, getFavoriteVideos, fetchFeaturedVideos, fetchFitnessVideoById, /*videoLibraryArticle,*/ fitnessVideo } = this.props;
    fetchVideoArticle();
    fetchRecommendedVideos();
    getFavoriteVideos(10);
    fetchFeaturedVideos();
    fetchFitnessVideoById(fitnessVideo[0]?.id)
  }

  updateUserPoints = () => {
    const { updateUserGlobalPoints,fitnessVideo  } = this.props;
    updateUserGlobalPoints(fitnessVideo[0].id, 6, this.callBack);
  };

  updateUserPointsForWebinar = () => {
    const { webinarLibraryArticle, updateUserGlobalPoints } = this.props;
    updateUserGlobalPoints(webinarLibraryArticle?.id, 18);
  };

  editVideo = (id) => {
    const { history } = this.props;
    history.push({pathname:`/education/videos-library/edit/${id}`,state:{categoryKey:"FITNESS"}});
  };

  onSave = () => {
    this.setState({
      save: !this.state.save,
    })
  };

  addLike = () => {
    this.setState({
      like: !this.state.like,
    })
  };

  addLikes = (journeys) => {
    const { onClickFavoriteRibbon } = this.props;
    onClickFavoriteRibbon("fitness", journeys?.id, journeys?.like_status ? 0 : 1, journeys?.favorite_status, 1)
    this.setState({
      like: !this.state.like,
      imagestatus: journeys?.like_status === 1 ? 1 : 0
    })

  };

  saveImage = () => {
    this.setState({
      save: !this.state.save,
    })
  }

  render() {
    const {location, featuredVideos, history, link, webinarLibraryArticle, webinarVideoIds, allowedRoles, onClickFavoriteRibbon, fitnessVideo, marginTop, t} = this.props;
    const { like, imagestatus } = this.state;
    const stringArray = location.pathname.split('/');
    const videoId = stringArray[stringArray.length - 1];
    if (isUndefined(fitnessVideo)) {
      return <Waiting />
    }
    return (
      <EngagementContainer margin={marginTop}>
        {stringArray[2] === 'videos-library' ?
          <SocialFeedsContainer videoCSS={1}>
            <Layout>
              <TitleContainer>
                <div>
                  <span >{t("Fitness Video")}</span>
                </div>
                <div>
                  <div>{(fitnessVideo && fitnessVideo.length > 0 && fitnessVideo[0]?.title !== null && fitnessVideo[0]?.title !== undefined) ? t(fitnessVideo[0]?.title) : null}</div>
                  <div>
                    {this.state?.save !== 0 || fitnessVideo[0]?.favorite_status ?
                      <img src="/public/images/NewDashboardV2/ribbonColor.png" onClick={() => { onClickFavoriteRibbon("fitness", fitnessVideo[0]?.id, 0, fitnessVideo[0]?.favorite_status ? 0 : 1, 0); this.saveImage() }} /> :
                      <img src="/public/images/NewDashboardV2/ribbonNew.png" onClick={() => { onClickFavoriteRibbon("fitness", fitnessVideo[0]?.id, 0, fitnessVideo[0]?.favorite_status ? 0 : 1, 0); this.saveImage() }} />
                    }
                  </div>
                </div>
              </TitleContainer>
              <div style={{ "margin-bottom": "25px" }}>
                <VideoContainerNew margin={"0px"} width={"460px"}>
                  <ReactPlayer
                    className="react-player"
                    url={fitnessVideo[0]?.video_url}
                    onEnded={this.updateUserPoints}
                    controls
                  />
                </VideoContainerNew>
                <ImageContainerIcons margin={"0px"}>
                  <div>
                    <div>
                      {!imagestatus && (like || fitnessVideo[0]?.like_status) ? <img src="/public/images/NewDashboardV2/ColorLike.png" alt="like" onClick={() => this.addLikes(fitnessVideo[0])} /> : <img src="/public/images/NewDashboardV2/like.png" alt="like" onClick={() => this.addLikes(fitnessVideo[0])} />}
                      <span>{fitnessVideo[0].likes_count}</span>
                    </div>

                    <div>
                      <img src="/public/images/NewDashboardV2/view.png" alt="view" />
                      <span>{fitnessVideo[0]?.seen_count}</span>
                    </div>
                  </div>
                  <div >
                    <CircleContainerNew style={{ "marginLeft": "15px" }}>
                      <div className="flex">
                        <Circle borderColor="#9c9c9c" checked={fitnessVideo[0]?.view_status}>
                          <Tick checked={fitnessVideo[0]?.view_status} />
                        </Circle>
                      </div>
                      <div className="quiz">
                        {t("Watched")}
                      </div>
                    </CircleContainerNew>
                  </div>
                </ImageContainerIcons>
              </div>
            </Layout>

            <div style={{ "display": "flex", "width": "100%", "flex-wrap": "wrap", "border-top": "2px solid rgba(156, 156, 156, 0.3)" }}>
              <RecommendedTitle videoCSS={1}>{t("Related Fitness Video")}</RecommendedTitle>
              <CardContainer>
                {isEmpty(featuredVideos) ? <NoComments>{t("No Recommended Fitness Videos")}</NoComments> :
                  featuredVideos.map((video, index) => (
                    <RecommendedVideos video={video} key={index} history={history} grid={0} link={link} margin={index % 2 === 1 ? "0px" : '0px'} bottom={"25px"} width={'calc((100% / 2) - 12.5px)'} onClickFavoriteRibbon={onClickFavoriteRibbon} />
                  ))
                }
              </CardContainer>
            </div>
          </SocialFeedsContainer> :
          <SocialFeedsContainer webinar videoCSS={1}>
            <Layout>
              <TitleContainer>
                <Title mainTitle>{webinarLibraryArticle.video_category} | {webinarLibraryArticle.category_name}</Title>
                <AdventureTitle>{webinarLibraryArticle?.title}</AdventureTitle>
              </TitleContainer>
              <ArticleButtonsContainer right>
                <BackButton onClick={() => history.push(`/education/${link}/${webinarLibraryArticle.category_name}`)}>
                  <img src="/public/images/educationAdventureIcons/back-arrow.png" alt="back-arrow" />
                  {t("Back")}
                </BackButton>
              </ArticleButtonsContainer>
              <VideoContainer>
                <ReactPlayer
                  className="react-player-webinar"
                  url={webinarLibraryArticle.video_url}
                  onEnded={this.updateUserPointsForWebinar}
                  controls
                />
              </VideoContainer>
              {
                videoId != webinarVideoIds[webinarVideoIds.length - 1] &&
                <ButtonContainer>
                  <Button onClick={this.getNextArticle}>
                    {t("continue")}
                    <i className="fas fa-angle-right" />
                  </Button>
                </ButtonContainer>
              }
            </Layout>
            <RecommendedTitle videoCSS={1}>{t("Recommended")}</RecommendedTitle>
            <div style={{ "display": "flex", "width": "100%" }}>
              <CardContainer>
                {isEmpty(featuredVideos) ? <NoComments>{t("No Recommended Fitness Videos")}</NoComments> :
                  featuredVideos.map((video, index) => (
                    <RecommendedVideos video={video} key={index} history={history} grid={0} link={link} margin={index % 2 === 1 ? "0px" : '25px'} bottom={"25px"} width={'calc((100% / 2) - 25px)'} onClickFavoriteRibbon={onClickFavoriteRibbon} />
                  ))
                }
              </CardContainer>
            </div>
          </SocialFeedsContainer>
        }

        {stringArray[2] === 'videos-library' &&
          <SideBarContainer videoCSS={1}>
            {allowedRoles &&
              <AdminButtons onClick={() => this.editVideo(fitnessVideo[0].id)} background={'#9FC989'}>
                <div>
                  <img src="/public/images/NewDashboardV2/editvideo.png" alt="editvideo" />
                </div>
                <div>
                  {t("Edit Video")}
                </div>
              </AdminButtons>
            }
            <AdventurePointDashBoard />
          </SideBarContainer>
        }
      </EngagementContainer>
    )
  }
}

VideoArticle.propTypes = {
  history: PropTypes.object.isRequired,
  fetchVideoArticle: PropTypes.func.isRequired,
  videoLibraryArticle: PropTypes.object,
  videoCount: PropTypes.number,
  videoIds: PropTypes.array,
  location: PropTypes.object.isRequired,
  updateUserGlobalPoints: PropTypes.func.isRequired,
  featuredVideos: PropTypes.array,
  link: PropTypes.string,
  fetchWebinarArticle: PropTypes.func.isRequired,
  webinarLibraryArticle: PropTypes.object,
  webinarVideoIds: PropTypes.array,
  fitnessVideo: PropTypes.object.isRequired,
  match: PropTypes.object,
  fetchFitnessVideoById: PropTypes.func.isRequired,
  allowedRoles: PropTypes.bool.isRequired,
  role: PropTypes.string.isRequired,
  onClickFavoriteRibbon: PropTypes.func,
  education: PropTypes.object,
  videos: PropTypes.array,
  fetchRecommendedVideos: PropTypes.func.isRequired,
  getFavoriteVideos: PropTypes.func,
  fetchFeaturedVideos: PropTypes.func.isRequired,
  data: PropTypes.array,
  marginTop: PropTypes.string,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  videoLibraryArticle: state.education.videoLibraryArticle,
  videoIds: state.education.videoIds,
  videoCount: state.education.videoCount,
  webinarLibraryArticle: state.education.webinarLibraryArticle,
  webinarVideoCount: state.education.webinarVideoCount,
  webinarVideoIds: state.education.webinarVideoIds,
  fitnessVideo: state.education.fitnessVideo,
  videos: state.education.videos,
  data: state.education.data,
});

const mapDispatchToProps = (dispatch) => ({
  fetchVideoArticle: (id) => dispatch(getVideoLibraryArticle(id)),
  fetchWebinarArticle: (id) => dispatch(getWebinarLibraryArticle(id)),
  fetchFitnessVideoById: (id) => dispatch(getFitnessVideoById(id)),
  fetchRecommendedVideos: () => dispatch(getRecommendedVideos()),
  getFavoriteVideos: (id) => dispatch(getFavoriteVideos(id)),
  fetchFeaturedVideos: () => dispatch(getFeaturedVideos()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTranslation()(VideoArticle)));